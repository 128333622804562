@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap');

.shortProject-container{
  background-color: #ffffff;
}

.section-title{
  font-family: "Playfair Display", serif;
  /* text-shadow: -2px -2px 0 #583f04, 2px -2px 0 #583f04, 2px 2px 0 #583f04,
  2px 2px 0 #583f04; */
  font-size: 4rem;
  color: #DB4629 ;
}
.project-card {
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.project-card:hover {
  transform: translateY(-10px); 
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); 
}

.project-image-wrapper {
  position: relative;
  overflow: hidden;
}

.project-image {
  width: 100%;
  height: auto;
  border-radius: 15px;
  transition: transform 0.5s ease-in-out;
}

.project-description {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(250, 249, 249, 0.847); 
  color: rgb(7, 5, 5);
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  border-radius: 15px;
}

.project-card:hover .project-image {
  transform: scale(1.1);
}

.project-card:hover .project-description {
  opacity: 1;
}
.text-title{
  font-family: "Playfair Display", serif;
}
.text-description{
  font-family: "Space Grotesk", sans-serif;
}